import React , { useRef, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { AiFillInstagram } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { FaSquareThreads } from "react-icons/fa6";
import { Link } from "react-router-dom";
function Contact() {
const [sendstatus,setSendStatus]=useState("Submit")

  const nameRef= useRef(null);
  const phone_numberRef= useRef(null);
  const messageRef= useRef(null);

  const handleSubmit =(e)=> {
    e.preventDefault();

    const data={
      name:nameRef.current.value,
      phone_no:phone_numberRef.current.value,
      message:messageRef.current.value,
    }
  setSendStatus("Sending...")
    fetch(`${process.env.REACT_APP_URL}/api/contact-us/`, {
      method: "POST",
      headers:{
        "Content-Type": "application/json",
        Authorization:`API_KEY ${process.env.REACT_APP_API_KEY}`
      },
      credentials:"include",
      body: JSON.stringify(data),
    })
    .then(checkStatus)
    .then((response) => response.json())
    .then((data)=>{
      setTimeout(()=>{

        nameRef.current.value=""
        phone_numberRef.current.value=""
        messageRef.current.value=""
        setSendStatus("Submit")

      },3000)
    })

    .catch((error) => {

    })
    
    }
  function checkStatus(response){
    if(response.ok){
        setSendStatus("Sent")
      
      return response;
    }else{
      throw new Error(response.statusText);

    }
  }


  return (
    <section className="contacts" id="Contact-Us">
      <div className="contact_container">
        <div className="form_main">
          <div className="contact_form">
            <p className="contact_ti">Let us know how we can help</p>
            <form onSubmit={handleSubmit}>
            <div className="form_field">
              <div className="contact_info">
               
                <input className="input" required type="text" ref={nameRef} placeholder="Full Name" />
                <hr className="hr" />
              </div>
              <div className="contact_info">
             
                <input className="input" type="text" required ref={phone_numberRef}  placeholder="Phone Number"/>
                <hr className="hr" />
              </div>
              <div className="contact_info">
             
              <textarea className="input" required name="" ref={messageRef}  id="" cols="30" rows="4" placeholder="Message" ></textarea>
             <hr className="hr" />
           </div>
           <input type="submit" value={sendstatus} className='watch_more send_mail'/>
          </div>
          </form>
        </div>
        
      </div>
      <div className="contact_back">
        <h3 className="contact_title"> Find us here </h3>
        <div className="contact_adress contact_location">
          <FaLocationDot />
          <div>Gazebo Square, Lebenz Tower, <br/>1 <sup>st</sup> Floor, Room 101.</div>
        </div>
        <div className="contact_adress contact_phone">
          <FaPhone />
          <div>+251920550240, +251923503833</div>
        </div>
        <div className="contact_adress contact_email">
          <BiLogoGmail />
          <div className="email-adress">afomdesign@gmail.com</div>
        </div>

        <div className="contact_social">
          <NavHashLink to="https://www.instagram.com/_afom/?hl=en">
            {" "}
            <AiFillInstagram className="contact_socials" />{" "}
          </NavHashLink>
          <NavHashLink to="https://web.facebook.com/afomdesign/">
            {" "}
            <BsFacebook className="contact_socials" />{" "}
          </NavHashLink>
          <NavHashLink to="https://www.threads.net/@_afom?xmt=AQGzTbze8wcq6M1BuHxG8esih5HORNuHiFC8in4lQ2WUn-4">
            {" "}
            <FaSquareThreads className="contact_socials" />{" "}
          </NavHashLink>
        </div>
        <Link to="appointment" style={{textDecoration:"none"}}><button className="book-btn">Book Appointment <LocalMallIcon sx={{fill:"black"}}/></button></Link>
      </div>
      </div>
    </section>
  );
}

export default Contact;
