import React from 'react'
import { AiOutlineDown,AiOutlineUp} from "react-icons/ai";
function Faq() {
    const handleDrop=(e)=>{
        const selected=document.getElementById(`q${e}`)
        const dropSelected=document.getElementById(`aq${e}a`)
        const dropSelectedU=document.getElementById(`aq${e}`)
        
        if(selected){
            selected.classList.toggle('activeans')
            dropSelected.classList.toggle('activedrop')
            dropSelectedU.classList.toggle('activedropu')
        }
    }
  return (
    <section className='faq' id="Faq">
        <div className="faq_container">
        <div className="faq_left">
            <div className="questions"   onClick={()=>handleDrop(1)}>
                <div className="q_container q1">
                    <p className='question '>Our design materials ?</p>
                    <div className="drop-icon"><AiOutlineDown size={25} style={{fontSize:"large"}} id="aq1a"/> <AiOutlineUp className='dropUp' size={25} style={{fontSize:"large"}} id="aq1"/></div>
                </div>
                    <p className="answer ans1" id="q1">In our designs, we utilize a variety of materials to create unique textures and styles. These include natural fabrics like cotton, silk, and wool, as well as synthetic options like polyester. We also incorporate luxurious materials such as satin and velvet, along with delicate fabrics like lace and tulle. Additionally, we work with twill and organza, and even use mikado for its elegant structure. Each material contributes to the overall aesthetic and feel of our designs.</p>
                
            </div>
            <div className="questions"   onClick={()=>handleDrop(2)}>
                <div className="q_container q2">
                    <p className='question '>Our work environment ?</p>
                    <div className="drop-icon"><AiOutlineDown size={25} style={{fontSize:"large"}} id="aq2a"/> <AiOutlineUp className='dropUp' size={25} style={{fontSize:"large"}} id="aq2"/></div>
                </div>
                    <p className="answer ans2" id="q2">Our work environment is both dynamic and creative. In the showroom, we take precise measurements and offer personalized consultations. Meanwhile, our workshop is a hub of artistry, where we engage in design, cutting, pattern making, tailoring, stitching, sampling, and embroidery. Each aspect of our work reflects our commitment to quality and craftsmanship.</p>
                
            </div>
            <div className="questions"   onClick={()=>handleDrop(3)}>
                <div className="q_container q3">
                    <p className='question '>How can I order ?</p>
                    <div className="drop-icon"><AiOutlineDown size={25} style={{fontSize:"large"}} id="aq3a"/> <AiOutlineUp className='dropUp' size={25} style={{fontSize:"large"}} id="aq3"/></div>
                </div>
                    <p className="answer ans3" id="q3">To place an order, simply schedule an appointment through our website. Choose a date that works for you, and then visit our workshop. We look forward to seeing you!</p>
                
            </div>
            <div className="questions"   onClick={()=>handleDrop(4)}>
                <div className="q_container q4">
                    <p className='question '>How many days will it take ?</p>
                    <div className="drop-icon"><AiOutlineDown size={25} style={{fontSize:"large"}} id="aq4a"/> <AiOutlineUp className='dropUp' size={25} style={{fontSize:"large"}} id="aq4"/></div>
                </div>
                    <p className="answer ans4" id="q4">The duration may take from 1 month and 15 days to 3 months spans.</p>
            </div>
            <div className="questions"   onClick={()=>handleDrop(5)}>
                <div className="q_container q5">
                    <p className='question '>What payment methods are available?</p>
                            <div className="drop-icon"><AiOutlineDown size={25} style={{fontSize:"large"}} id="aq5a"/> <AiOutlineUp className='dropUp' size={25} style={{fontSize:"large"}} id="aq5"/> </div>
                </div>
                    <p className="answer ans5" id="q5">we accept Telebirr, CBE, Awash, Abyssinia, PayPal, Mastercard</p>
                
            </div>
            <div className="questions"   onClick={()=>handleDrop(6)}>
                <div className="q_container q6">
                    <p className='question '>Where can you find us?</p>
                            <div className="drop-icon"><AiOutlineDown size={25} style={{fontSize:"large"}} id="aq6a"/> <AiOutlineUp className='dropUp' size={25} style={{fontSize:"large"}} id="aq6"/> </div>
                </div>
                    <p className="answer ans6" id="q6">Gazebo Square, Lebenz Tower, 1st Floor, Room 101.</p>
                
            </div>
        </div>
        <div className="faq_right">
            <div className="faq_header">
            <p>- FAQ</p>
            </div>
            <h1 className='questionslook'>Questions?<br/> Look here.</h1>
        </div>
        </div>
    </section>
  )
}

export default Faq
