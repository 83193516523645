import React, { useState,useEffect,useRef } from 'react'
import Logo from "../assets/Logo.png"
import axios from 'axios';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QRCode from "react-qr-code";
import { useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import html2canvas from 'html2canvas';
import DownloadIcon from '@mui/icons-material/Download';
import Appointment from './Appointment';
function Ticket() {
    

  const [message,setMessage]=useState(null)
  const [loading,setLoading]=useState(true)
  const divRef = useRef();
    const [schedule,setSchedule]=useState({
        client_name:null,
    appointment_date:null,
    status:null
      })
    const {id}=useParams()
const months=[
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]


    const exportAsImage = async (element, imageFileName) => {
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL('image/png', 1.0);
      downloadImage(image, imageFileName);
    };

    const downloadImage = (blob, fileName) => {
      const fakeLink = document.createElement('a');
      fakeLink.style.display = 'none';
      fakeLink.download = fileName;
      fakeLink.href = blob;
      document.body.appendChild(fakeLink);
      fakeLink.click();
      document.body.removeChild(fakeLink);
      fakeLink.remove();
    };
    const getAppointment=async ()=>{
      await axios.get(`${process.env.REACT_APP_URL}/api/appointment/${id}/`,{
        headers:{
          Authorization:`API_KEY ${process.env.REACT_APP_API_KEY}`
        }
      }).then(res=>{
        setLoading(false)
        setSchedule(res.data)
        
      }).catch(err=>{
        setMessage("Invalid Ticket")
        setLoading(false)
        console.log(err);
        
      })
    }

    useEffect(()=>{
      getAppointment()
    },[])
  return (
    <div className='ticket-page'>
     { message?null:
     <>
     <div>Download Ticket</div>
      <div onClick={() => exportAsImage(divRef.current, 'Ticket.png')} style={{cursor:"pointer"}}>
       <DownloadIcon/>
      </div>
     </>}
     
      <div ref={divRef} className="ticket">
        <div className="ticket-top">
            <div className="ticket_logo">
                <img src={Logo} alt="logo" width="95px"/>
            </div>
            {
              loading? <div className="ticket-loading" ></div> :   
              message?<h1 style={{color:"black",margin:"auto"}}>{message}</h1>: 
            <div className="ticket-appointment-detail">
                <div className="ticket-date"><PersonIcon style={{fill:"#111111"}}/> {schedule?.client_name}</div>
                <div className="ticket-date"><AccessTimeIcon style={{fill:"#111111"}}/>{`${schedule?.appointment_date?.split("-")[2].split("T")[0]} ${months[schedule?.appointment_date?.split("-")[1]]} ${schedule?.appointment_date?.split("-")[0]} ${schedule?.appointment_date?.split("T")[1]?.split(":")[0]}:${schedule?.appointment_date?.split("T")[1]?.split(":")[1]}`}</div>
                <div className="ticket-info"><InfoIcon style={{fill:"#111111"}}/> {schedule?.status}</div>
                <div className="ticket-location"><LocationOnIcon style={{fill:"#111111"}}/> Gazebo Square, Lebenz Tower,
                1 st Floor, Room 101.</div>
            </div>
              }
        </div>
        <div className="ticket-bottom">
          {message?<div className="caution">
            <h5>Important Notice:</h5>
            <p>Make sure the appointment is set Properly</p>
        </div>: 
        loading?null:
          <>
            <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "45%", width: "35%" }}
            value= {`https://afombridal.com/ticketscan/${id}`}
            viewBox={`0 0 256 256`}
        />
        <div className="caution">
            <h5>Important Notice:</h5>
            <p>Scan this QR code whenever you need to check your appointment status. Additionally, make sure to save this ticket for future reference.</p>
        </div>
          </>
            }
        
        </div>
      </div>
    </div>
  )
}

export default Ticket
