import "./App.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { Route, Routes,useNavigate } from "react-router-dom";
import BlogDetail from "./pages/BlogDetail";
import MoreBlog from "./pages/MoreBlog";
import MoreCollection from "./pages/MoreCollection";
import CollectionDetail from "./pages/CollectionDetail";
import Landingpage from "./pages/Landingpage";
import { NavHashLink } from 'react-router-hash-link';
import { useEffect, useState } from "react";
import AdminAuth from "./pages/admin/AdminAuth";
import CollectionPost from "./pages/admin/CollectionPost";
import BlogPost from "./pages/admin/BlogPost";
import Notfound from "./pages/Notfound";
import Dashboard from "./pages/admin/Dashboard";
import Managepost from "./pages/admin/Managepost";
import Editcollection from "./pages/admin/Editcollection";
import EditBlog from "./pages/admin/EditBlog";
import Calendar from "./Components/Calendar";
import Appointment from "./pages/Appointment";
import MyAppointments from "./pages/admin/MyAppointments";
import Fitdate from "./pages/admin/Fitdate";
import { AuthContext } from "./Context/AuthContext";
import ProtectedRoute from "./Protected/ProtectedRoute";
import Ticket from "./pages/Ticket";
import TicketScanned from "./pages/TicketScanned";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';



function App() {


  const [authtokens,setAuthtokens]=useState(()=>localStorage.getItem('authTokens')?JSON.parse(localStorage.getItem('authTokens')): null)
  const [user,setUser]=useState(()=>localStorage.getItem('authTokens')?jwtDecode(localStorage.getItem('authTokens')): null)
  const [isLoading, setIsLoading] = useState(true);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState('Login');

    const navigate = useNavigate();

  let btnScrollToTop;

  const assign = () => {
    btnScrollToTop = document.getElementById('scrolltotop');
  };

  useEffect(() => {
    assign();
  }, []);

  window.addEventListener('scroll', (e) => {
    if (btnScrollToTop) {
      btnScrollToTop.style.display = window.scrollY > 706.56 ? 'flex' : 'none';
    }
  });





 // Get CSRF Token
 const getCsrfToken = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('csrftoken='))
    ?.split('=')[1];
  return cookieValue || '';
};

const handleLogin = async (e) => {
  e.preventDefault();
  setLoginStatus('Logingin');
  
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL}/api/token/`, {
      username,
      password
    }, {
      withCredentials: true,
      headers: {
        'X-CSRFToken': getCsrfToken(),
      }
    });



    
    setLoginStatus("Login")
    // // Set cookies for access and refresh tokens
    // document.cookie = `access_token=${response.data.access}; path=/;`;
    // document.cookie = `refresh_token=${response.data.refresh}; path=/;`;

    if(response.status===200){
      setUser(jwtDecode(response.data.access))
      setAuthtokens(response.data)
      localStorage.setItem('authTokens',JSON.stringify(response.data))
      navigate('/dashboard');

    }
    // Update auth context

    // Redirect to dashboard
    // navigate('/dashboard');
  } catch (err) {
    setLoginStatus('Login');
    setUser(null);
    setAuthtokens(null);

    console.error("err",err.response);
    console.error(err.response);

    // Error handling based on status
    if (err.response?.status === 401) {
      toast.error('Incorrect credentials. Please try again.');
    } else if (err.response?.status === 400) {
      toast.error('Bad request. Please check your input.');
    } else {
      toast.error('An unexpected error occurred.');
    }
  }
};

const handleLogout=()=>{
  localStorage.removeItem("authTokens")
  setAuthtokens(null)
  setUser(null)
  navigate("/adminauth")
}


const UpdateToken=async ()=>{
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/token/refresh/`,
          { refresh: authtokens?.refresh },
          {
            headers: {
              'X-CSRFToken': getCsrfToken(),
              'Authorization': `Bearer ${authtokens?.access}`,
            }
          }
        );

        if(response.status===200){
          setUser(jwtDecode(response.data.access))
          setAuthtokens(response.data)
          localStorage.setItem('authTokens',JSON.stringify(response.data))
          if(isLoading){
            setIsLoading(false)
          }
        }else{
          handleLogout()
        }

      } catch (err) {
        handleLogout()
      }
}

const contextData={
  username:username, 
  setUsername:setUsername,
  password:password, 
  setPassword:setPassword,
  loginStatus:loginStatus, 
  setLoginStatus:setLoginStatus,
  user:user, 
  setUser:setUser,
  handleLogin:handleLogin,
  handleLogout:handleLogout,
  UpdateToken:UpdateToken,
  isLoading:isLoading,
  setIsLoading:setIsLoading
}








  return (
    <div className="App">
      <AuthContext.Provider value={contextData}>
        <Navbar />
        {/* hero */}
        <Routes>
          <Route index element={<Landingpage />}/>
          <Route path="/adminauth" element={<AdminAuth />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/postcollection" element={<CollectionPost />} />
            <Route path="/postblog" element={<BlogPost />} />
            <Route path="/myappointments" element={<MyAppointments />} />
            <Route path="/fitdate/:id" element={<Fitdate />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/managepost/editcollection/:id" element={<Editcollection />} />
            <Route path="/managepost/editblog/:id" element={<EditBlog />} />
            <Route path="/managepost" element={<Managepost />} />
          </Route>

          <Route path="/appointment" element={<Appointment />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/collections/:id" element={<CollectionDetail />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/moreblogs" element={<MoreBlog />} />
          <Route path="/morecollections" element={<MoreCollection />} />
          <Route path="/ticket/:id" element={<Ticket/>}/>
          <Route path="/ticketscan/:id" element={<TicketScanned/>}/>
          <Route path="*" element={<Notfound />} />
        </Routes>
        
        <Footer />
      </AuthContext.Provider>
    </div>

  );
}

export default App;