import React,{useState,useEffect} from 'react'
import Logo from "../assets/Logo.png"
import axios from 'axios';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';

function TicketScanned() {
  const [message,setMessage]=useState(null)
  const [loading ,setLoading]=useState(true)
  const [schedule,setSchedule]=useState({
    client_name:null,
appointment_date:null,
status:null

  })
  const {id}=useParams()
  const months=[
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
  



  const getAppointment=async ()=>{
    await axios.get(`${process.env.REACT_APP_URL}/api/appointment/${id}/`,{
      headers:{
        Authorization:`API_KEY ${process.env.REACT_APP_API_KEY}`
      }
    }).then(res=>{
      setLoading(false)
      setSchedule(res.data)
      
    }).catch(err=>{
      setMessage("Invalid Ticket")
      setLoading(false)
      console.log(err);
      
    })
  }

  useEffect(()=>{
    getAppointment()
  },[])

  
  return (
    <div className='ticket-page'>
      <div className="ticket">
        <div className="ticket-top">
            <div className="ticket_logo">
                <img src={Logo} alt="logo" width="95px"/>
            </div>
              {
              loading? <div className="ticket-loading" ></div> : 
              message?<h1 style={{color:"black",margin:"auto"}}>{message}</h1>:    
            <div className="ticket-appointment-detail">
                <div className="ticket-date"><PersonIcon style={{fill:"#111111"}}/> {schedule?.client_name}</div>
                <div className="ticket-date"><AccessTimeIcon style={{fill:"#111111"}}/>{`${schedule?.appointment_date?.split("-")[2].split("T")[0]} ${months[schedule?.appointment_date?.split("-")[1]]} ${schedule?.appointment_date?.split("-")[0]} ${schedule?.appointment_date?.split("T")[1]?.split(":")[0]}:${schedule?.appointment_date?.split("T")[1]?.split(":")[1]}`}</div>
                <div className="ticket-info"><InfoIcon style={{fill:"#111111"}}/> {schedule?.status}</div>
                <div className="ticket-location"><LocationOnIcon style={{fill:"#111111"}}/> Gazebo Square, Lebenz Tower,
                1 st Floor, Room 101.</div>
            </div>
              }
        </div>
        <div className="ticket-bottom">
        {
              loading?null:
              message?<div className="caution">
                <h5>Important Notice:</h5>
                <p>Make sure the appointment is set Properly</p>
            </div>:
        <div className="caution">
            <h5>Important Notice:</h5>
            <p>Please check the status. If the status is fitting or requires checking, ensure you are present at the showroom on the specified date and time.</p>
        </div>
}
        </div>
      </div>
    </div>
  )
}

export default TicketScanned
