import React, { useState, useEffect, useContext } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import axios from 'axios';
import Loading from '../Components/Loading';

function ProtectedRoute() {
  const { user,isLoading,UpdateToken,authtokens} = useContext(AuthContext);
  // const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(()=>{

    if(isLoading){
      UpdateToken()
    }
    let fourMinute=1000*60*4
  
    let interval= setInterval(() => {
      if(authtokens){
  
        UpdateToken()
      }else{
        console.log("skipped");
      }
  
    }, fourMinute);
    return ()=>clearInterval(interval)
  },[authtokens])

  
  // // Get CSRF Token
  // const getCsrfToken = () => {
  //   const cookieValue = document.cookie
  //     .split('; ')
  //     .find(row => row.startsWith('csrftoken='))
  //     ?.split('=')[1];
  //   return cookieValue || '';
  // };

  // useEffect(() => {
  //   const refreshToken = document.cookie
  //     .split('; ')
  //     .find(row => row.startsWith('refresh_token='))
  //     ?.split('=')[1];
  //   const accessToken = document.cookie
  //     .split('; ')
  //     .find(row => row.startsWith('access_token='))
  //     ?.split('=')[1];

  //   const authenticateUser = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_URL}/api/token/refresh/`,
  //         { refresh: refreshToken },
  //         {
  //           headers: {
  //             'X-CSRFToken': getCsrfToken(),
  //             'Authorization': `Bearer ${accessToken}`,
  //           }
  //         }
  //       );

  //       
  //     } catch (err) {
  //       navigate('/adminauth');
  //     }
  //   };

  //   if (refreshToken && accessToken) {
  //     authenticateUser();
  //   } else {
  //     setIsAuth(false);
  //     setIsLoading(false);
  //     navigate('/adminauth');
  //   }

  // }, [navigate, setIsAuth]);

  if (isLoading) {
    return <Loading />;  // Show a loading spinner while checking authentication
  } else {
    return user ? <Outlet /> : <Navigate to="/adminauth" />;
  }
}

export default ProtectedRoute;
