import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Loader';
import { AuthContext } from '../../Context/AuthContext';

function AdminAuth() {
  const { username,
    setUsername,
    password, 
    setPassword,
    loginStatus,
    handleLogin } = useContext(AuthContext);

  return (
    <div className="admin-body">
      <div className="toast-container">
        <ToastContainer toastStyle={{ color: 'black' }} />
      </div>
      <div className="login-card">
        <div className="login-card-header"><h1>Login</h1></div>
        <form className="login-card-body" onSubmit={handleLogin}>
          <div className="username-container">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
            />
          </div>
          <div className="password-container">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>
          <div className="forgot-pas">Forgot Password?</div>
          {loginStatus === "Login" ? (
            <button className="login-btn" type="submit" style={{ border: "1px solid #f2f2f2" }}>
              Login
            </button>
          ) : (
            <button className="login-btn" disabled style={{ border: "1px solid #f2f2f2", display: "grid", placeItems: "center" }}>
              <Loader />
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default AdminAuth;
