import React from "react";

function About_us() {
  return (
    <section className="about_us" id="About-Us">
      <div className="about_us-container">
        <div className="about_us-left">
          <div className="about_us-left-top"></div>
          <div className="about_us-left-bottom">
            <p className="about_us-pitch">We believe fashion should empower, inspire, and celebrate the fierce spirit of modern women. Each creation is meticulously designed and crafted, using luxurious fabrics and intricate details, making every piece a true work of art.</p>
          </div>
        </div>
        <div className="about_us-right">
          <div className="about_us-right-top">
            <h1 className="about_us-header">About us</h1>
            <p className="about-afom_des">At AFOM, we specialize in handcrafted wedding dresses and high couture outfits for bold women ready to make a statement. Our talented lead designer, Afomia Getu, trained by leading specialists in Bangkok, brings a unique vision to each piece, ensuring every garment reflects strength and individuality.</p>
          </div>
          <div className="about_us-right-bottom"></div>
        </div>
      </div>
      
    </section>
  );
}

export default About_us;
